import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFound = () => (
  <Layout>
    <Seo image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" title="404: Not found" />
    <div className="container">
      <div className="page-404 text-center">
        <h1 className="title">404: Not Found</h1>
        <p className="mb-3">You just hit a route that doesn&#39;t exist...</p>
        <Link to="/" aria-label="Back to Home" className="btn btn-danger">Back to home</Link>
      </div>
    </div>
  </Layout>
)

export default NotFound
